import { useState } from 'react';

import { NavItem } from './NavItem';
import { NavRow } from './NavRow';

import { Button } from 'components/Buttons';
import { MegaMenu } from 'components/Header/lib/MegaMenu';
import { Logo } from './Logo';
import { Accordion } from 'components/Accordion';
import mobileNavAccordionData from 'shared/test/accordions/mobile-nav-accordion.json';
import mobileNavAccordionPortalData from 'shared/test/accordions/mobile-nav-simple-accordion-portal.json';
import mobileNavAccordionPartnersData from 'shared/test/accordions/mobile-nav-simple-accordion-partners.json';
import mobileNavAccordionDonateData from 'shared/test/accordions/mobile-nav-simple-accordion-donate.json';
import mobileNavAccordionLoginData from 'shared/test/accordions/mobile-nav-simple-accordion-login.json';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faClose } from '@fortawesome/free-solid-svg-icons';

import { SearchRedirectInput } from 'components/Inputs'

export const Nav = () => {
  const [itemHovered, setItemHovered] = useState(false);
  const [nestedItemHovered, setNestedItemHovered] = useState(false);
  const [subMenuItems, setSubMenuItems] = useState(<></>);
  const [nestedSubMenuItems, setNestedSubMenuItems] = useState(<></>);
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [currentSubNavColor, setCurrentSubNavColor] = useState('');
  const [showDesktopSearch, setShowDesktopSearch] = useState(false);

  const handleItemBlur = () => {
    setItemHovered(false);
  };

  const handleNestedItemBlur = () => {
    setNestedItemHovered(false);
  };

  const handleItemHovered = (items: any, nestedItems?: any) => {
    setItemHovered(true);
    if (items) setSubMenuItems(items);

    nestedItems ? setNestedItemHovered(true) : setNestedItemHovered(false);
    setNestedSubMenuItems(nestedItems);

    if (items === null && (nestedItems && nestedItems === null)) hideSubMenus();
  };

  const hideSubMenus = () => {
    setItemHovered(false);
    setNestedItemHovered(false);
    setCurrentSubNavColor('');
    setItem('');
  };

  const setItem = (itemName: string) => {
    localStorage.setItem('currentNavItem', itemName);
  }

  return (
    <>
      <div id='nav_actions'>
        <Logo />
        <Button
          customClassName='fa--white'
          icon={`${showMobileNav ? 'close' : 'bars'}`}
          iconOnly={true}
          id='menu_btn'
          onClick={() => setShowMobileNav(!showMobileNav)}
        />
      </div>
      <nav className={`mobile${showMobileNav ? '' : ' hide'}`}>
        <div className='navbars'>
          <div className='navbars__sub'>
            <ul>
              <li>
                <Button customClassName={'button--btn-nav'} text='Discover' link={'/discover'} />
              </li>
              <li>
                <Button customClassName={'button--btn-nav'} text='Engage' link={'/engage'} />
              </li>
              <li>
                <Button customClassName={'button--btn-nav'} text='Give' link={'/give'} />
              </li>
              <li>
                <Button customClassName={'button--btn-nav'} text='Receive' link={'/receive'} />
              </li>
              <li>
                <Button customClassName={'button--btn-nav'} text="What's New" link={'/blog'} />
              </li>
            </ul>
          </div>
          <div className='navbars__accordion'>
            <Accordion
              accordion={mobileNavAccordionData}
              simpleAccordions={
                [
                  mobileNavAccordionPortalData,
                  mobileNavAccordionPartnersData,
                  mobileNavAccordionDonateData,
                  mobileNavAccordionLoginData
                ]
              }
            />
            <div>
              <h3><a className='start-fund background background--brand' href='https://www.cfengage.org/start-your-fund' target='_blank' rel='noreferrer'>START YOUR FUND</a></h3>
            </div>
          </div>
          <SearchRedirectInput position='header-mobile' />
        </div>
      </nav>
      <nav className={`desktop ${itemHovered && Object.keys(subMenuItems?.props)[0] ? 'desktop-expanded' : ''}`} onBlur={handleItemBlur} onMouseLeave={() => hideSubMenus()}>
        <NavRow style={'nav__row nav__row--colors'}>
          <FontAwesomeIcon
            icon={!showDesktopSearch ? faSearch : faClose}
            onClick={() => setShowDesktopSearch(!showDesktopSearch)}
            className='nav__row--search'
          />
          <NavItem
            contents={'ACCESS MY FUND'}
            onHover={() => {
              //setItem('fund-advisor');
              //setCurrentSubNavColor('background--malibu_blue');
              handleItemHovered(
                <>
                  <NavItem contents={'Richmond CF'} link={'https://tcfrichmond.fcsuite.com/erp/fundmanager'} rel={true} />
                  <NavItem contents={'Gloucester CF'} link={'https://tcfrichmond.fcsuite.com/erp/fundmanager/list?sorg_id=1000'} rel={true} />
                  <NavItem contents={'Mathews CF'} link={'https://tcfrichmond.fcsuite.com/erp/fundmanager/list?sorg_id=1001'} rel={true} />
                  <NavItem contents={'River Counties CF'} link={'https://tcfrichmond.fcsuite.com/erp/fundmanager/list?sorg_id=1002'} rel={true} />
                </>
              );

            }}
            subMenuItems={subMenuItems}
            color={currentSubNavColor}
            itemName='fund-advisor'
            link={"https://tcfrichmond.fcsuite.com/erp/fundmanager"}
            rel={true}
          />
          <NavItem
            contents={'PARTNERS'}
            color={currentSubNavColor}
            itemName='partners'
            link={"https://www.cfengage.org/partnership"}
            rel={true}
            onHover={() => {
              //setItem('partners');
              //setCurrentSubNavColor('background--nav-periwinkle');
              handleItemHovered(
                <>
                  <NavItem
                    contents={'Affiliates'}
                    color='background--light_periwinkle'
                    itemName='affiliates'
                    link='/discover/faces-of-philanthropy/affiliates'
                    onHover={() => handleItemHovered(null,
                      <>
                        <NavItem contents={'Gloucester'} link={'/discover/faces-of-philanthropy/affiliates/gcf'} />
                        <NavItem contents={'Mathews'} link={'/discover/faces-of-philanthropy/affiliates/mcf'} />
                        <NavItem contents={'River Counties'} link={'/discover/faces-of-philanthropy/affiliates/rccf'} />
                      </>
                    )}
                  />
                  <NavItem
                    contents={'Changemakers'}
                    onHover={() => handleItemHovered(null, null)}
                    onBlur={setNestedSubMenuItems(<></>)}
                    link='https://www.cfengage.org/changemakers'
                    rel={true}
                  />
                  <NavItem
                    color='background--light_periwinkle'
                    contents={'Giving Circles'}
                    itemName='giving_circles'
                    link={'/discover/faces-of-philanthropy/giving-circles'}
                    onHover={() => {
                      //setItem('giving-circles')
                      handleItemHovered(null,
                        <>
                          <NavItem contents={'Mathews Women'} link='/discover/faces-of-philanthropy/giving-circles/mathews-women' />
                          <NavItem contents={'OuRVA Collective'} link='/discover/faces-of-philanthropy/giving-circles/ourva' />
                          <NavItem contents={'SisterFund'} link='/discover/faces-of-philanthropy/giving-circles/sisterfund' />
                          <NavItem contents={'Ujima Legacy Fund'} link='/discover/faces-of-philanthropy/giving-circles/ujima' />
                        </>
                      )
                    }}
                  />
                  <NavItem
                    contents={'Nonprofits'}
                    onBlur={setNestedSubMenuItems(<></>)}
                    onHover={() => handleItemHovered(null, null)}
                    link='https://www.cfengage.org/nonprofits'
                    rel={true}
                  />
                  <NavItem
                    contents={'Supporting Organizations'}
                    link='/discover/faces-of-philanthropy/support-organizations'
                    onHover={() => handleItemHovered(null,
                      <>
                        <NavItem contents={'Jenkins Foundation'} link='/discover/faces-of-philanthropy/support-organizations/jenkins' />
                        <NavItem contents={'R.E.B. Foundation'} link='/discover/faces-of-philanthropy/support-organizations/reb' />
                      </>
                    )}
                  />
                </>
              )
            }}
          />
          <NavItem
            contents={'DONATE'}
            itemName='donate'
            color='background--grass'
            link='https://tcfrichmond.fcsuite.com/erp/donate'
            rel={true}
            onHover={() => {
              //setItem('donate');
              //setCurrentSubNavColor('background--grass');
              handleItemHovered(
                <>
                  <NavItem contents={'Richmond Area Funds'} link='https://tcfrichmond.fcsuite.com/erp/donate' rel={true} />
                  <NavItem contents={'Gloucester Funds'} link='https://tcfrichmond.fcsuite.com/erp/donate/list?sorg_id=1000' rel={true} />
                  <NavItem contents={'Mathews Funds'} link='https://tcfrichmond.fcsuite.com/erp/donate/list?sorg_id=1001' rel={true} />
                  <NavItem contents={'River Counties Funds'} link='https://tcfrichmond.fcsuite.com/erp/donate/list?sorg_id=1002' rel={true} />
                </>
              )
            }}
          />
          <NavItem
            contents={'START YOUR FUND'}
            itemName='start_fund'
            link='https://www.cfengage.org/start-your-fund'
            rel={true}
            color='radius background--white'
            onHover={() => {
              //setItem('start_fund');
              //setCurrentSubNavColor('background--white');
              handleItemHovered(
                <></>
              );
            }}
          />
          <NavItem
            contents={
              <span><FontAwesomeIcon className='navbar-icon' icon={'user'} />LOG IN</span>
            }
            itemName='log_in'
            color='radius background--white'
            onHover={() => {
              handleItemHovered(
                <>
                  <NavItem contents={'Access My Fund'} link='https://tcfrichmond.fcsuite.com/erp/fundmanager' rel={true} />
                  <NavItem contents={'CFengage'} link='https://www.cfengage.org/' rel={true} />
                  <NavItem contents={'Grants'} link='https://www.grantinterface.com/Home/Logon?urlkey=tcfrichmond' rel={true} />
                  <NavItem contents={'Scholarships'} link='https://www.grantinterface.com/Home/Logon?urlkey=tcfrichmondscholarship' rel={true} />
                </>
              )
            }}
          />
          {showDesktopSearch && <SearchRedirectInput position='header-desktop' />}
        </NavRow>
        <NavRow style={`sub-nav ${(itemHovered && Object.keys(subMenuItems?.props)[0]) ? '' : 'hide'}`}>
          {subMenuItems}
        </NavRow>
        {nestedSubMenuItems && <NavRow onBlur={handleNestedItemBlur} style={`sub-nav ${(!nestedItemHovered) ? 'hide' : ''}`}>
          {nestedSubMenuItems}
        </NavRow>}
      </nav>
      <MegaMenu showDesktopSearch={showDesktopSearch} />
    </>
  );
}
